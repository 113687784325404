import React, { useEffect, useState } from "react";
import "../../../styles/inlineMobile.css";
import InlineTopBar from "../InlineTopBar";
import InlineAccordian from "./InlineAccordian";
import CtaWorkflow from "../../../../../ctas/workflow/CtaWorkflow";
import { useSelector } from "react-redux";
import GoogleAdsWorkflow from "../../../../../common/googleAds/workflow/GoogleAdsWorkflow";
import Refinery89Ads from "../../../../../common/googleAds/components/Refinery89Ads";
import Feedback from "../../../../reusable/components/feedbacks/Feedback";
import PoweredBy from "../../../../reusable/components/poweredBy/PoweredBy";
import useXrayInlineMobile from "../../../controllers/useXrayInlineMobile";
import useXrayInline from "../../../controllers/useXrayInline";

function InlineMobile() {
  const [showContent, setShowContent] = useState(false);

  const showGoogleAd = useSelector(
    (state) => state.flipcard.data?.config?.googleAdConfig?.isActive
  );

  const showRefineryAdConfig = useSelector(
    (state) => state.flipcard.data?.config?.refineryAdConfig?.isActive
  );

  const isXrayCollapsed = useSelector((state) => state.app.isXrayCollapsed);

  const {
    allOptions,
    selectedOption,
    ctaIndex,
    isShowCta,
    onSelectedOptionHandler,
    handleCtaBackButton,
  } = useXrayInline(true);

  useEffect(() => {
    let timer;
    if (!isXrayCollapsed) {
      timer = setTimeout(() => setShowContent(true), 200);
    } else {
      setShowContent(false);
    }
    return () => clearTimeout(timer);
  }, [isXrayCollapsed]);

  return (
    <div className="xray">
      <InlineTopBar
        isShowCta={isShowCta()}
        handleCtaBackButton={handleCtaBackButton}
        isDesktop={true}
        selectedOption={selectedOption}
        allOptions={allOptions}
      />

      <div
        className={`xrayInlineMobileBody customScrollBar ${
          isShowCta() && "xrayBgBlur"
        }`}
      >
        <InlineAccordian
          allOptions={allOptions}
          onSelectedOptionHandler={onSelectedOptionHandler}
          selectedOption={selectedOption}
        />
        <div
          style={{
            padding: "0px var(--mpr-1-2)",
          }}
        >
          <Feedback isShowCta={isShowCta()} />
        </div>

        {showGoogleAd && (
          <div className={`${isShowCta() && "xrayBgBlur"} googleAdsParent `}>
            <GoogleAdsWorkflow />
          </div>
        )}

        {showRefineryAdConfig && <Refinery89Ads />}
      </div>
      {isShowCta() && (
        <div className="gatedCtaOverlay xrayOverlayInlineMobile">
          <CtaWorkflow actionIndex={ctaIndex} isTransparent={true} />
        </div>
      )}

      <PoweredBy isShowCta={isShowCta()} />
    </div>
  );
}

export default InlineMobile;
