import "../styles/rag.css";
import TopBar from "../components/TopBar";
import Body from "../components/Body";
import useRagController from "../controllers/useRagController";
import { useSelector } from "react-redux";

function One() {
  const {
    isLoading,
    inputQuery,
    responseData,
    isRagCollapsed,
    setInputQuery,
    handleGenerateAnswer,
    handRatingTake,
    error,
    handeLinkClick,
  } = useRagController();

  const language = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.language
  );

  return (
    <div className="rag">
      <TopBar
        isLoading={isLoading}
        handleGenerateAnswer={handleGenerateAnswer}
        inputQuery={inputQuery}
        setInputQuery={setInputQuery}
        isRagCollapsed={isRagCollapsed}
        language={language}
        error={error}
        responseData={responseData}
      />
      <Body
        responseData={responseData}
        isRagCollapsed={isRagCollapsed}
        setInputQuery={setInputQuery}
        handleGenerateAnswer={handleGenerateAnswer}
        language={language}
        handRatingTake={handRatingTake}
        handeLinkClick={handeLinkClick}
      />
    </div>
  );
}

export default One;
