import "../styles/rag.css";
import useRagController from "../controllers/useRagController";
import { useSelector } from "react-redux";
import TopBarTwo from "../components/TopBarTwo";
import BodyTwo from "../components/BodyTwo";

function Two() {
  const {
    isLoading,
    inputQuery,
    responseData,
    isRagCollapsed,
    setInputQuery,
    handleGenerateAnswer,
    handRatingTake,
    error,
    isMobileView,
    handeLinkClick,
  } = useRagController();

  const language = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.language
  );

  return (
    <div className="rag ragTwo">
      <TopBarTwo
        isLoading={isLoading}
        handleGenerateAnswer={handleGenerateAnswer}
        inputQuery={inputQuery}
        setInputQuery={setInputQuery}
        isRagCollapsed={isRagCollapsed}
        language={language}
        error={error}
        responseData={responseData}
      />
      <BodyTwo
        responseData={responseData}
        isRagCollapsed={isRagCollapsed}
        setInputQuery={setInputQuery}
        handleGenerateAnswer={handleGenerateAnswer}
        language={language}
        handRatingTake={handRatingTake}
        isMobileView={isMobileView}
        handeLinkClick={handeLinkClick}
      />
    </div>
  );
}

export default Two;
