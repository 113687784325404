import { useSelector } from "react-redux";
import Two from "../two/Two";
import One from "../one/One";

function RagWorkflow() {
  const engagementTemplate = useSelector(
    (state) =>
      state.flipcard.data?.flipCard?.engagement?.engagementData
        ?.engagementTemplate
  );

  if (engagementTemplate === "two") {
    return <Two />;
  } else {
    return <One />;
  }
}

export default RagWorkflow;
