import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    isFlipped: false,
    isShowEndScreen: false,
    engagementTimer: 0,
    isEngagementView: false,
    ctaViews: [],
    isEngTaken: false,
    isCtaTaken: false,
    ctaTextColor: "white",
    isShowEngResult: false,
    bridgedLogo:
      "https://storage.googleapis.com/bridged-assets/23fd80ae-c2ed-4d1b-b43d-1d89c75759fc",
    crouselCurrentCta: null,
    message: {
      isVisible: false,
      messageData: {
        message: "Oops something went wrong",
        type: "error",
      },
    },
    isXrayCollapsed: true,
    isRagCollapsed: true,
    isRagFirstQuestion: false,
    isRagTwoQuestionAsked: 1,
    linkedActions: [],
    inlineXrayHeaderSize: 75,
    outerBorderRadius: 15,
    isRagExceedsScroll: false,
  },
  reducers: {
    flipTheCard: (state) => {
      state.isFlipped = !state.isFlipped;
    },
    setIsFlipped: (state, action) => {
      state.isFlipped = action.payload;
    },
    setIsShowEndScreen: (state, action) => {
      state.isShowEndScreen = action.payload;
    },
    setEngagementTimer: (state, action) => {
      state.engagementTimer = action.payload;
    },
    setIsEngagementView: (state, action) => {
      state.isEngagementView = action.payload;
    },
    addCtaViews: (state, action) => {
      state.ctaViews = [...state.ctaViews, action.payload];
    },
    setShowMessage: (state, action) => {
      state.message = { messageData: action.payload, isVisible: true };
    },
    setIsEngTaken: (state, action) => {
      state.isEngTaken = action.payload;
    },
    setIsCtaTaken: (state, action) => {
      state.isCtaTaken = action.payload;
    },
    setCtaTextColor: (state, action) => {
      state.ctaTextColor = action.payload;
    },
    setIsShowEngResult: (state, action) => {
      state.isShowEngResult = action.payload;
    },
    setBridgedLogo: (state, action) => {
      state.bridgedLogo = action.payload;
    },
    setCrouselCurrentCta: (state, action) => {
      state.crouselCurrentCta = action.payload;
    },
    setHideMessage: (state) => {
      state.message.isVisible = false;
    },
    setIsXrayCollapsed: (state, action) => {
      state.isXrayCollapsed = action.payload;
    },
    setIsRagCollapsed: (state, action) => {
      state.isRagCollapsed = action.payload;
    },
    setLinkedActions: (state, action) => {
      state.linkedActions = action.payload;
    },
    setinlineXrayHeaderSize: (state, action) => {
      state.inlineXrayHeaderSize = action.payload;
    },
    setIsRagCollapsed: (state, action) => {
      state.isRagCollapsed = action.payload;
    },
    setIsRagFirstQuestion: (state, action) => {
      state.isRagFirstQuestion = action.payload;
    },
    setIsRagTwoQuestionAsked: (state, action) => {
      state.isRagTwoQuestionAsked = action.payload;
    },
    setOuterBorderRadius: (state, action) => {
      state.outerBorderRadius = action.payload;
    },
    setIsRagExceedsScroll: (state, action) => {
      state.isRagExceedsScroll = action.payload;
    },
  },
});

export const {
  flipTheCard,
  setIsFlipped,
  setIsShowEndScreen,
  setEngagementTimer,
  setIsEngagementView,
  setIsCtaView,
  addCtaViews,
  setShowMessage,
  setIsEngTaken,
  setIsCtaTaken,
  setCtaTextColor,
  setIsShowEngResult,
  setBridgedLogo,
  setCrouselCurrentCta,
  setHideMessage,
  setIsXrayCollapsed,
  setLinkedActions,
  setinlineXrayHeaderSize,
  setIsRagCollapsed,
  setIsRagFirstQuestion,
  setIsRagTwoQuestionAsked,
  setOuterBorderRadius,
  setIsRagExceedsScroll,
} = appSlice.actions;

export default appSlice.reducer;
