import React from "react";
import { useSelector } from "react-redux";

function BodyFAQ({ setInputQuery, handleGenerateAnswer, language }) {
  const questions = useSelector(
    (state) => state.flipcard.data.flipCard.engagement.engagementData?.questions
  );

  const suggestedQuestions = useSelector(
    (state) => state.language.data.suggestedQuestions
  );

  if (questions?.length) {
    return (
      <div
        style={{ paddingBottom: "var(--mpr-1-2)" }}
        className={language === "he" && "ragRtl"}
      >
        <div className="ragBodyFaqs">
          <h4>{suggestedQuestions}</h4>

          <ul>
            {questions?.map((data, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setTimeout(() => {
                      setInputQuery(data);
                      handleGenerateAnswer(data);
                    }, 200);
                  }}
                >
                  {data}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  return <></>;
}

export default BodyFAQ;
