import React, { useEffect, useRef, useState } from "react";
import Icon from "../../../../utils/components/Icon";
import { useSelector } from "react-redux";
import Markdown from "react-markdown";
import Divider from "../../../../utils/components/Divider";
import ScrollAnimation from "../../../../utils/components/scrollAnimation/ScrollAnimation";
import BodyFAQ from "./BodyFAQ";
import StarRatings from "react-star-ratings";

function BodyTwo({
  responseData,
  isRagCollapsed,
  setInputQuery,
  handleGenerateAnswer,
  language,
  handRatingTake,
  isMobileView,
  handeLinkClick,
}) {
  const feedbackConfig = useSelector(
    (state) => state.flipcard.data.config?.feedbackConfig
  );

  const isRagExceedsScroll = useSelector(
    (state) => state.app.isRagExceedsScroll
  );

  const questions = useSelector(
    (state) => state.flipcard.data.flipCard.engagement.engagementData?.questions
  );

  const footer = useSelector((state) => state.flipcard.data.config?.footer);

  const [isVisible, setIsVisible] = useState(false);
  const { answerText, questionText, referenceArticle } = useSelector(
    (state) => state.language.data
  );

  const scrollableBodyRef = useRef(null);

  useEffect(() => {
    if (!isRagCollapsed) {
      setTimeout(() => {
        function handleScroll() {
          if (scrollableBodyRef.current) {
            const { scrollHeight, scrollTop, clientHeight } =
              scrollableBodyRef.current;
            const isScrollable = scrollHeight > clientHeight;
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 15; // Adjusted for the 20px safe zone

            if (isScrollable) {
              if (isAtBottom) {
                setIsVisible(false);
              } else {
                setIsVisible(true);
              }
            } else {
              setIsVisible(false);
            }
          }
        }

        handleScroll();

        if (scrollableBodyRef.current) {
          scrollableBodyRef.current.addEventListener("scroll", handleScroll);
        }

        return () => {
          if (scrollableBodyRef.current) {
            scrollableBodyRef.current.removeEventListener(
              "scroll",
              handleScroll
            );
          }
        };
      }, 500);
    }
  }, [scrollableBodyRef, isRagCollapsed]);

  // Scroll to the top when responseData changes
  useEffect(() => {
    if (scrollableBodyRef.current) {
      scrollableBodyRef.current.scrollTo({
        top: 0,
        behavior: "smooth", // Optional for smooth scrolling
      });
    }
  }, [responseData]);

  const isRagScrollable = () => {
    if (isMobileView) {
      return "";
    }

    if (responseData?.length > 1) {
      return "scroll";
    }
    if (isRagExceedsScroll) {
      return "scroll";
    }
  };

  return (
    <>
      {!isRagCollapsed && (
        <>
          {responseData?.length === 0 && (
            <BodyFAQ
              setInputQuery={setInputQuery}
              handleGenerateAnswer={handleGenerateAnswer}
              language={language}
            />
          )}
          {responseData?.length > 0 && (
            <div
              className="ragBody customScrollBar"
              style={{
                overflow: isRagScrollable(),
              }}
              ref={scrollableBodyRef}
            >
              {responseData?.map((data, index) => {
                const responseAnswer = data?.response;

                return (
                  <div
                    style={{
                      marginBottom:
                        responseData?.length === 1 && "var(--mpr-3)",
                    }}
                  >
                    <div key={index}>
                      <div
                        className={`ragAnswerDiv ${
                          language === "he" && "ragRtl"
                        }`}
                      >
                        <div>
                          <h4 className="ragAnswerDivHeading">
                            {questionText}:
                          </h4>
                          <h3>{data?.question}</h3>
                        </div>
                        <div>
                          <h4 className="ragAnswerDivHeading">{answerText}:</h4>
                          <Markdown className="ragAnswerMainDiv">
                            {responseAnswer}
                          </Markdown>
                        </div>
                      </div>
                      {data?.reference?.length ? (
                        <div
                          style={{
                            marginTop: "var(--mpr-2)",
                          }}
                          className={
                            language === "he" && "ragRtl" + " ragReferenceDiv"
                          }
                        >
                          <h4
                            style={{ marginBottom: "var(--mpr-2)" }}
                            className="ragReferenceDivTitle"
                          >
                            {referenceArticle}:
                          </h4>
                          <ul className="ragReferenceLinks">
                            {data?.reference?.map((link, index) => {
                              return (
                                <li key={index}>
                                  <a
                                    href={link?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() => {
                                      handeLinkClick(link?.actionId);
                                    }}
                                  >
                                    {link?.title}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : null}

                      <div
                        style={{
                          marginTop: "var(--mpr-2)",
                        }}
                      >
                        {feedbackConfig?.isActive && (
                          <div
                            style={{
                              backgroundColor: "#F2F2ED",
                              padding: "var(--mpr-2)",
                              borderRadius: "var(--mpr-mini)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                              gap: "var(--mpr-3)",
                            }}
                          >
                            <p style={{ fontWeight: 400, opacity: 0.7 }}>
                              {feedbackConfig?.title || "Rate your experience"}
                            </p>
                            <div>
                              <StarRatings
                                rating={data?.feedback?.rating}
                                starRatedColor="#ffa106"
                                changeRating={(e) => {
                                  if (!data?.feedback?.isTaken) {
                                    handRatingTake(e, index);
                                  }
                                }}
                                numberOfStars={5}
                                name="rating"
                                starDimension="22px"
                                starSpacing="1px"
                                starHoverColor="#ffa106"
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      {index !== responseData?.length - 1 && (
                        <Divider style={{ marginTop: "var(--mpr-3)" }} />
                      )}
                    </div>
                  </div>
                );
              })}

              {!isRagCollapsed && (
                <ScrollAnimation
                  isVisible={isVisible}
                  style={{ left: "90%" }}
                />
              )}
            </div>
          )}
        </>
      )}

      <>
        {footer?.isActive && (
          <div
            style={{
              paddingBottom: questions?.length > 0 && "var(--mpr-3)",
            }}
          >
            <div
              className={
                responseData?.length === 1
                  ? "ragFooter ragFooterFirst"
                  : " ragFooter ragFooterAllResponses"
              }
            >
              <h5
                dangerouslySetInnerHTML={{
                  __html: footer?.title,
                }}
              />
            </div>
          </div>
        )}
      </>
    </>
  );
}

export default BodyTwo;
