import DmgWorkflow from "../../presentation/engagements/dmg/workflows/DmgWorkflow";
import GatewayWorkflow from "../../presentation/engagements/gateway/workflow/GatewayWorkflow";
import PollWorkflow from "../../presentation/engagements/poll/workflow/PollWorkflow";
import QuizWorkflow from "../../presentation/engagements/quiz/workflow/QuizWorkflow";
import RagWorkflow from "../../presentation/engagements/rag/workflow/RagWorkflow";
import SliderPollWorkflow from "../../presentation/engagements/sliderPoll/workflow/SliderPollWorkflow";
import XRayWorkflow from "../../presentation/engagements/x-ray/workflow/XRayWorkflow";

const totalEngagementTypes = [
  {
    name: "Slider Poll",
    value: "sliderPoll",
    component: SliderPollWorkflow,
  },
  {
    name: "Poll",
    value: "poll",
    component: PollWorkflow,
  },
  {
    name: "Gateway",
    value: "gateway",
    component: GatewayWorkflow,
  },
  {
    name: "Quiz",
    value: "quiz",
    component: QuizWorkflow,
  },
  {
    name: "Summary Engagement",
    value: "x-ray",
    component: XRayWorkflow,
  },
  {
    name: "RAG",
    value: "rag",
    component: RagWorkflow,
  },
  {
    name: "RAG",
    value: "analyticsRag",
    component: DmgWorkflow,
  },
];

export default totalEngagementTypes;
