import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../utils/components/Input";
import Button from "../../../../utils/components/Button";
import { setIsRagCollapsed } from "../../../../redux/slices/appSlice";
import Icon from "../../../../utils/components/Icon";
import usePostMessage from "../../../../utils/controllers/usePostMessage";

function TopBar({
  isLoading,
  handleGenerateAnswer,
  inputQuery,
  setInputQuery,
  isRagCollapsed,
  language,
  error,
  responseData,
}) {
  const questions = useSelector(
    (state) => state.flipcard.data.flipCard.engagement.engagementData?.questions
  );

  const isDarkTheme = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.darkTheme
  );

  const poweredByBridged = useSelector(
    (state) => state.flipcard.data.config?.poweredByBridged
  );

  const injectionStyle = useSelector(
    (state) => state.flipcard.data.config.injectionStyle
  );

  const { ragLoaderTexts, generateText, answersGenerated_TEXT } = useSelector(
    (state) => state.language.data
  );

  const { postMessage } = usePostMessage();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [loaderTextIndex, setLoaderTextIndex] = useState(0);

  useEffect(() => {
    if (isLoading) {
      if (loaderTextIndex < ragLoaderTexts?.length - 1) {
        const interval = setInterval(() => {
          setLoaderTextIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            if (newIndex >= ragLoaderTexts?.length - 1) {
              clearInterval(interval);
            }
            return newIndex;
          });
        }, 2000);
        return () => clearInterval(interval);
      }
    } else {
      setLoaderTextIndex(0);
    }
  }, [isLoading, loaderTextIndex]);

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const engagementTitle = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.engagement?.title
  );

  const { poweredBy_TEXT, enterQuestion } = useSelector(
    (state) => state.language.data
  );

  const logo = useSelector(
    (state) => state.flipcard.data.flipCard.publisher.logo
  );

  const bridgedLogo = useSelector((state) => state.app.bridgedLogo);

  const handleGenerate = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }
      const newErrors = {};

      if (!inputQuery.trim()) {
        newErrors.inputQuery = "Please enter a question.";
      }

      setErrors(newErrors);

      if (Object.keys(newErrors)?.length === 0) {
        handleGenerateAnswer();
      }
    },
    [inputQuery, handleGenerateAnswer]
  );

  const closeIcon = (style) => {
    const isInline = injectionStyle === "inline";
    if (isRagCollapsed) {
      return (
        <Icon
          name={isInline ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"}
          onClick={() => {
            onCollapseHandler(false);
            sendCollapseEvent();
          }}
          style={{ ...style }}
        />
      );
    } else {
      return (
        <Icon
          name={isInline ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"}
          onClick={() => {
            onCollapseHandler(true);
            sendCollapseEvent();
          }}
          style={{
            ...style,
          }}
        />
      );
    }
  };

  const onCollapseHandler = (data) => {
    dispatch(setIsRagCollapsed(data));
  };

  const sendCollapseEvent = () => {
    postMessage({
      event: "toggleCollapsion",
      trackerId: getQueryParam("trackerId"),
    });
  };

  const handleLoaderOpacity = () => {
    if (isLoading) {
      return 1;
    }

    if (isRagCollapsed && responseData?.length > 0) {
      return 1;
    }

    return 0;
  };

  return (
    <div className="ragTopBar">
      {(responseData?.length > 0 || questions?.length > 0) &&
        closeIcon({
          opacity: 0.8,
          cursor: "pointer",
          position: "fixed",
          top: "var(--mpr-2)",
          right: language === "he" ? "none" : "var(--mpr-2)",
          left: language === "he" ? "var(--mpr-2)" : "none",
        })}
      <div
        style={{
          display: "grid",
          marginBottom: "var(--mpr-3)",
          textAlign: language === "he" ? "right" : "left",
        }}
      >
        {!logo && !poweredByBridged ? (
          <></>
        ) : (
          <div
            className="ragLogoDiv"
            style={{
              justifyContent: language === "he" ? "flex-end" : "flex-start",
            }}
          >
            {!logo && <p>{poweredBy_TEXT}</p>}
            <img
              onClick={() => {
                if (!logo) {
                  window.open("https://bridged.media/", "_blank");
                }
              }}
              src={logo || bridgedLogo}
              alt="Logo"
            />
          </div>
        )}
        <h3 className="ragTopBarHeading">
          {engagementTitle || "Ask a question related to article."}
        </h3>

        {error?.isError ? (
          <h4
            style={{
              opacity: 1,
              fontWeight: 400,
              color: "red",
            }}
          >
            {error?.message}
          </h4>
        ) : (
          <>
            <h4
              style={{
                opacity: handleLoaderOpacity(),
                fontWeight: 400,
                color: isDarkTheme ? "yellow" : "#395EF9",
              }}
            >
              {!isLoading && (
                <>
                  {isRagCollapsed &&
                    responseData?.length > 0 &&
                    ` ${responseData?.length} ${answersGenerated_TEXT}`}
                </>
              )}

              {isLoading ? ragLoaderTexts[loaderTextIndex] : "."}
            </h4>
          </>
        )}
      </div>

      {/* <TopBarFAQ
        setInputQuery={setInputQuery}
        handleGenerateAnswer={handleGenerateAnswer}
      /> */}

      <div style={{ height: "60px", marginTop: "var(--mpr-1-2)" }}>
        <form
          onSubmit={handleGenerate}
          className={`ragInputDiv ${language === "he" && "ragRtl"}`}
        >
          <Input
            placeholder={enterQuestion}
            value={inputQuery}
            onChange={(e) => {
              setInputQuery(e.target.value);
              if (errors.inputQuery) {
                setErrors({ ...errors, inputQuery: undefined });
              }
            }}
            error={errors.inputQuery}
          />

          <Button isLoading={isLoading} onClick={handleGenerate}>
            {generateText}
          </Button>
        </form>
      </div>
    </div>
  );
}

export default TopBar;
