import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsRagCollapsed,
  setIsRagFirstQuestion,
  setIsRagTwoQuestionAsked,
} from "../../../../redux/slices/appSlice";
import usePostMessage from "../../../../utils/controllers/usePostMessage";
import {
  useCtaTakeMutation,
  useEngagementTakeMutation,
  useSubmitRagQueryMutation,
  useUserFeedbackMutation,
} from "../../../../services/api";
import useGetWindowWidth from "../../../../utils/controllers/useGetWindowWidth";

function useRagController() {
  const [isMobileView, setIsMobileView] = useState(false);
  const engagementTemplate = useSelector(
    (state) =>
      state?.flipcard?.data?.flipCard?.engagement?.engagementData
        ?.engagementTemplate
  );
  const width = useGetWindowWidth();
  const [error, setIsError] = useState({
    isError: false,
    message: "",
  });
  const campaignType = useSelector(
    (state) => state?.flipcard?.data?.config?.campaignType
  );
  const dispatch = useDispatch();
  const isRagCollapsed = useSelector((state) => state.app.isRagCollapsed);
  const isRagTwoQuestionAsked = useSelector(
    (state) => state.app.isRagTwoQuestionAsked
  );
  const engagementId = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.engagement?._id
  );

  const options = useSelector(
    (state) => state.flipcard.data.flipCard?.engagement?.engagementData?.options
  );

  const [isLoading, setIsLoading] = useState(false);
  const [inputQuery, setInputQuery] = useState("");
  const [responseData, setResponseData] = useState([]);
  const { postMessage } = usePostMessage();

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const [_ENGAGEMENT_TAKE] = useEngagementTakeMutation();
  const [_SEND_RAG_QUERY] = useSubmitRagQueryMutation();

  const handleGenerateAnswer = async (localQuestion) => {
    setIsLoading(true);
    takeAPi();

    try {
      const response = await _SEND_RAG_QUERY({
        question: inputQuery || localQuestion,
      });

      if (response?.data?.data?.data) {
        const sentData = {
          ...response?.data?.data?.data,
          question: inputQuery || localQuestion,
          ragResultId: response?.data?.data.ragResultId,
          feedback: {
            isTaken: false,
            rating: 0,
          },
        };

        setResponseData([sentData, ...responseData]);
        setInputQuery("");
        dispatch(setIsRagCollapsed(false));
        if (isRagCollapsed) {
          sendCollapseEvent();
        }
        setIsError({
          isError: false,
          message: "",
        });
      }

      if (response?.error) {
        setIsError({
          isError: true,
          message:
            response?.error?.data?.errorObject?.userErrorText ||
            "Something went wrong. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const takeAPi = async (_id) => {
    let optionId = "";

    if (options?.length > 0) {
      optionId = options[0]?._id;
    }
    const sentData = {
      engagementId: engagementId,
      optionId: optionId,
    };

    await _ENGAGEMENT_TAKE(sentData);
  };

  const sendCollapseEvent = () => {
    postMessage({
      event: "toggleCollapsion",
      trackerId: getQueryParam("trackerId"),
    });
  };

  useEffect(() => {
    if (responseData?.length === 1) {
      dispatch(setIsRagFirstQuestion(true));
    }

    if (engagementTemplate === "two") {
      dispatch(setIsRagTwoQuestionAsked(!isRagTwoQuestionAsked));
    }
  }, [responseData, engagementTemplate]);

  const [_FEEDBACK] = useUserFeedbackMutation();
  const handRatingTake = async (newRating, currentIndex) => {
    const updatedResponseData = [...responseData];
    if (updatedResponseData[currentIndex]) {
      updatedResponseData[currentIndex].feedback = {
        isTaken: true,
        rating: newRating,
      };
      setResponseData(updatedResponseData);
      let sentData = {
        rate: newRating,
        feedbackData: {
          campaignType: campaignType || "",
          ragResultId: updatedResponseData[currentIndex]?.ragResultId || "",
        },
      };
      await _FEEDBACK(sentData);
    }
  };

  useEffect(() => {
    if (engagementTemplate === "two") {
      if (width <= 500) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }
  }, [width, engagementTemplate]);

  const [_CTA_TAKE] = useCtaTakeMutation();
  const handeLinkClick = async (actionId) => {
    await _CTA_TAKE({
      actionId: actionId,
      takeActionData: {
        actionType: "link",
      },
    });
  };

  return {
    isLoading,
    inputQuery,
    responseData,
    isRagCollapsed,
    setInputQuery,
    handleGenerateAnswer,
    handRatingTake,
    error,
    isMobileView,
    handeLinkClick,
  };
}

export default useRagController;
